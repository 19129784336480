<template>
  <div class="">
    <el-card shadow="never">
      <el-button type="primary" @click="dialogVisible = true"
        >添加模型</el-button
      >
      <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
      >
        <template v-slot:table>
          <el-table-column align="center" type="index" label="序号" />
          <el-table-column prop="userName" align="center" label="封面" />
          <el-table-column prop="userName" align="center" label="模型标题" />
          <el-table-column prop="userName" align="center" label="地址" />
          <el-table-column prop="userName" align="center" label="上传者" />
          <el-table-column prop="userName" align="center" label="上传时间" />
          <el-table-column prop="userName" align="center" label="播放量PV " />
          <el-table-column prop="userName" align="center" label="操作" />
        </template>
      </commonTable>
    </el-card>
    <el-dialog
      title="添加文章"
      :visible.sync="dialogVisible"
      width="50%"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="78px"
      >
        <el-form-item label="标题" prop="title">
          <el-input
            v-model="ruleForm.options"
            placeholder="请输入标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="封面" prop="title">
          <el-upload
            :class="{
              'avatar-uploader': true,
              'avatar-coverImg': true,
              disabled: uploadDisabled,
            }"
            :action="$store.state.uploadingUrl"
            list-type="picture-card"
            :on-progress="handProgress"
            :on-success="handleAvatarSuccessCoverImg"
            :on-remove="handleRemove"
            :file-list="fileList"
            :limit="1"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              *格式png、jpg，大小不超过2MB。
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="文章内容" prop="title">
          <quill-editor
            style="height: 100%; width: 100%"
            v-model="ruleForm.details"
            ref="myQuillEditor"
            :options="editorOption"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { quillEditor, Quill } from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = "请填写文章内容";
export default {
  components: {
    commonTable,
  },
  data() {
    return {
      editorOption: quillConfig,
      dialogVisible: false,
      ruleForm: {
        name: "",
        options: "",
        coverImg: "",
        details: "",
      },
      rules: {},
      options: [
        {
          value: "选项1",
          label: "自定义媒体页",
        },
        {
          value: "选项2",
          label: "短视频",
        },
      ],
      value: "",
      tableData: [],
      pageSize: "",
      currentPage: "",
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    handleAvatarSuccessCoverImg(res, file) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.coverImg = `https://cdn-oss.jquen.com/${res.data}`;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.coverImg = "";
    },
  },
};
</script>

<style>
</style>